body { background-color: $COL25; }
body { color: $COL16; }
[data-pad="0"] { margin-top: 10px; }

@media #{$medium-up} {
[data-pad="0"] { margin-top: 20px; }

}
[data-pad="1"] { margin-top: 5px; }

h1 {
font-family: 'Poppins';
font-weight: 700;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 30px;

@media #{$medium-up} {
font-size: 38px;

}
@media #{$large-up} {
font-size: 40px;

}
}
h2 {
font-family: 'Poppins';
font-weight: 700;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 30px;

@media #{$medium-up} {
font-size: 38px;

}
@media #{$large-up} {
font-size: 40px;

}
}
h3 {
font-family: 'Poppins';
font-weight: 700;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 20px;

}
h4 {
font-family: 'Poppins';
font-weight: 700;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 24px;

@media #{$medium-up} {
font-size: 28px;

}
}
h5 {
color: $COL13;
font-family: 'Poppins';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 28px;

@media #{$medium-up} {
font-size: 38px;

}
@media #{$large-up} {
font-size: 42px;

}
}
h6 {
font-family: 'Poppins';
font-weight: normal;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 24px;

@media #{$large-up} {
font-size: 30px;

}
}
.button {
font-family: 'Poppins';
font-weight: 700;
font-style: normal;
line-height: 1.5;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
@media #{$large-up} {
font-size: 20px;

}
}
.me-Quote .quote-body {
font-family: 'Arapey';
font-weight: normal;
font-style: italic;
line-height: 1.2;
letter-spacing: 0;
font-size: 20px;

@media #{$large-up} {
font-size: 22px;

}
}
.me-Quote .quote-author {
font-family: 'Poppins';
font-weight: 700;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
ol,ul {
font-family: 'Poppins';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

@media #{$large-up} {
font-size: 18px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Caveat';
font-weight: normal;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 28px;

@media #{$medium-up} {
font-size: 34px;

}
}
summary {
font-family: 'Poppins';
font-weight: 500;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 18px;

@media #{$large-up} {
font-size: 20px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Poppins';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

}
nav.mainmenu > .menu-item > div > a {
font-family: 'Poppins';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

}
nav.secondarymenu > .menu-item > a {
font-family: 'Poppins';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Poppins';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 20px;

}
body {
font-family: 'Poppins';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

@media #{$large-up} {
font-size: 18px;

}
}
p { padding-bottom: 1em; }
a {color: $COL2}
a:hover {color: $COL3}
/* Dark:44 */
.MES44 {
background-color: $COL23;
color: $COL24;
padding: 15px;

 }
.MES44 {
background-color: $COL23;
color: $COL24;
padding: 15px;

h1.MEC44, h2.MEC44, h3.MEC44, h4.MEC44, h5.MEC44, h6.MEC44 { color: $COL24;
 }
 }
a.MEC44 { color: $COL5;
&:hover { color: $COL4; }
 }
cite.MEC44{
color: $COL24;
}
/* Search Panel:33 */
.MES33 {
background-color: $COL12;
border-radius: 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 10px;

@media #{$medium-up} {
padding: 15px;

}
@media #{$large-up} {
padding: 20px 30px;

}
 }
.MES33 {
background-color: $COL12;
border-radius: 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 10px;

@media #{$medium-up} {
padding: 15px;

}
@media #{$large-up} {
padding: 20px 30px;

}
 }
/* Profile Heading Size:34 */
.MES34 {
 }
.MES34 {
h1.MEC34 { font-size: 24px; }
h1.MEC34 { @media #{$medium-up} { font-size: 34.2px; }; }
h1.MEC34 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC34 { font-size: 24px; }
h2.MEC34 { @media #{$medium-up} { font-size: 34.2px; }; }
h2.MEC34 { @media #{$large-up} { font-size: 40px; }; }
h3.MEC34 { font-size: 16px; }
h3.MEC34 { @media #{$medium-up} { font-size: 18px; }; }
h3.MEC34 { @media #{$large-up} { font-size: 20px; }; }
h4.MEC34 { font-size: 19.2px; }
h4.MEC34 { @media #{$medium-up} { font-size: 25.2px; }; }
h4.MEC34 { @media #{$large-up} { font-size: 28px; }; }
h5.MEC34 { font-size: 22.4px; }
h5.MEC34 { @media #{$medium-up} { font-size: 34.2px; }; }
h5.MEC34 { @media #{$large-up} { font-size: 42px; }; }
h6.MEC34 { font-size: 19.2px; }
h6.MEC34 { @media #{$medium-up} { font-size: 21.6px; }; }
h6.MEC34 { @media #{$large-up} { font-size: 30px; }; }
 }
/* Search Button:35 */
.MES35 {
background-color: $COL4;
&:hover { background-color: $COL23;}
color: $COL5;
&:hover { color: $COL5;}
font-size: 12.8px;
@media #{$medium-up} {
font-size: 16.2px;
};
@media #{$large-up} {
font-size: 20px;
};
border-radius: 0 50% 50% 0;
border-width: 1px;
border-style: solid;
border-color: $COL1;
 }
/* Contact Button:36 */
.MES36 {
background-color: $COL1;
&:hover { background-color: $COL4;}
color: $COL6;
&:hover { color: $COL5;}
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.6px;
};
@media #{$large-up} {
font-size: 14px;
};
border-radius: 50px;
padding: 5px 10px;

border-width: 1px;
border-style: solid;
border-color: $COL6;
 }
/* Login Button:37 */
.MES37 {
color: $COL6;
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.6px;
};
@media #{$large-up} {
font-size: 12px;
};
padding: 5px;

 }
/* form style:38 */
.me-block.me-Form.MES38,  body.MES38 {background-color:transparent;
  
 input[type='submit']{border-style: solid;border-width: 0;
padding: 15px 10px;

border-radius: 50px;
}
input:not([type='submit']), textarea, select {border-style: solid;
border-width: 0px 0px 2px 0px;
border-radius: 1px 1px 1px 1px;
border-color: $COL9;
}

  }
/* Warning:39 */
.MES39 {
background-color: $COL9;
color: $COL5;
font-size: 16px;
@media #{$large-up} {
font-size: 18px;
};
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 10px;

@media #{$large-up} {
padding: 20px;

}
border-width: 2px;
border-style: solid;
border-color: transparent;
border-top-style: dashed;
border-right-style: dashed;
border-bottom-style: dashed;
border-left-style: dashed;
 }
.MES39 {
background-color: $COL9;
color: $COL5;
font-size: 16px;
@media #{$large-up} {
font-size: 18px;
};
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 10px;

@media #{$large-up} {
padding: 20px;

}
border-width: 2px;
border-style: solid;
border-color: transparent;
border-top-style: dashed;
border-right-style: dashed;
border-bottom-style: dashed;
border-left-style: dashed;
 }
cite.MEC39{
color: $COL5;
font-size: 16px;
@media #{$large-up} {
font-size: 18px;
};
}
/* Round image:40 */
.MES40 {
border-radius: 50%;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: $COL1;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES40 {
border-radius: 50%;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: $COL1;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* dots:41 */
.MES41 {
& .slick-slider{padding: 15px 15px 30px 15px;}

& .slick-dots {text-align: center;
 button {margin: 0 5px;
;border-color: $COL1;
border-radius: 50%;
border-style:solid;
border-width:5px;
@media #{$medium-up} {
border-width: 5px;
};
@media #{$large-up} {
border-width: 5px;};
&:hover{border-color: $COL5;
;}
background-color: $COL5;
background-clip: padding-box;
&:hover {background-color: $COL26;}
width:20px;
height:20px;
@media #{$medium-up} {
width:20px;
height:20px;
};
@media #{$large-up} {
width:20px;
height:20px;
};
&:hover{background-color: $COL26;
;}}}
& .slick-dots .slick-active {
  button{
border-color: $COL5;
background-color: $COL26;
  }
}
& .slick-dots{bottom:10px;
} }
/* Image Mask Panel:42 */
.MES42 {
background-color: $COL28;
color: $COL25;
 }
.MES42 {
background-color: $COL28;
color: $COL25;
 }
cite.MEC42{
color: $COL25;
}
/* Image drop area:43 */
.MES43 {
font-size: 16px;
@media #{$large-up} {
font-size: 18px;
};
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 20px;

@media #{$large-up} {
padding: 40px;

}
border-width: 2px;
border-style: solid;
border-color: $COL10;
border-top-style: dashed;
border-right-style: dashed;
border-bottom-style: dashed;
border-left-style: dashed;
 }
.MES43 {
font-size: 16px;
@media #{$large-up} {
font-size: 18px;
};
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 20px;

@media #{$large-up} {
padding: 40px;

}
border-width: 2px;
border-style: solid;
border-color: $COL10;
border-top-style: dashed;
border-right-style: dashed;
border-bottom-style: dashed;
border-left-style: dashed;
 }
cite.MEC43{
font-size: 16px;
@media #{$large-up} {
font-size: 18px;
};
}
/* Dark:45 */
.MES45 {
background-color: $COL29;
color: $COL24;
padding: 15px;

 }
.MES45 {
background-color: $COL29;
color: $COL24;
padding: 15px;

h1.MEC45, h2.MEC45, h3.MEC45, h4.MEC45, h5.MEC45, h6.MEC45 { color: $COL24;
 }
 }
cite.MEC45{
color: $COL24;
}
/* Subscribe button:31 */
.MES31 {
background-color: $COL6;
&:hover { background-color: $COL22;}
color: $COL5;
&:hover { color: $COL5;}
font-size: 19.2px;
@media #{$medium-up} {
font-size: 21.6px;
};
@media #{$large-up} {
font-size: 20px;
};
border-radius: 50px;
padding: 15px 40px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Dark:46 */
.MES46 {
background-color: $COL30;
color: $COL24;
padding: 15px;

 }
.MES46 {
background-color: $COL30;
color: $COL24;
padding: 15px;

h1.MEC46, h2.MEC46, h3.MEC46, h4.MEC46, h5.MEC46, h6.MEC46 { color: $COL24;
 }
 }
cite.MEC46{
color: $COL24;
}
/* Dark 3:47 */
.MES47 {
background-color: $COL31;
color: $COL24;
padding: 15px;

 }
.MES47 {
background-color: $COL31;
color: $COL24;
padding: 15px;

h1.MEC47, h2.MEC47, h3.MEC47, h4.MEC47, h5.MEC47, h6.MEC47 { color: $COL24;
 }
 }
cite.MEC47{
color: $COL24;
}
/* Accordion:48 */
details.MES48 {
& > summary{background-color: $COL32;
&:hover {background-color: $COL35;}
}
color: $COL36;
& > article { color: $COL36;
 }
& > summary { color: $COL36;
 }
 }
/* Artwork Panel:49 */
.MES49 {
background-color: $COL8;
color: $COL17;
padding: 0 20px;

@media #{$large-up} {
padding: 0 40px 30px 40px;

}
 }
.MES49 {
background-color: $COL8;
color: $COL17;
padding: 0 20px;

@media #{$large-up} {
padding: 0 40px 30px 40px;

}
h1.MEC49, h2.MEC49, h3.MEC49, h4.MEC49, h5.MEC49, h6.MEC49 { color: $COL17;
 }
 }
cite.MEC49{
color: $COL17;
}
/* white dotted line:50 */
.MES50 {
padding: 10px 0;

& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: $COL5;}
& > hr {border-top-style: dashed;}
 }
/* Shade 2:51 */
.MES51 {
background-color: $COL10;
&:hover { background-color: $COL9;}
color: $COL5;
 }
/* Button Green:52 */
.MES52 {
background-color: $COL23;
color: $COL5;
 }
/* Home banner artist text:53 */
.MES53 {
color: $COL5;
 }
.MES53 {
color: $COL5;
 }
cite.MEC53{
color: $COL5;
}
/* FAQ:54 */
details.MES54 {
& > summary{background-color: $COL8;
&:hover {background-color: $COL7;}
}
& > summary{padding: 10px 15px;}

& > article{padding: 15px;}

& > article {border-width: 1px;
border-style: solid;
border-color: transparent transparent $COL5 transparent;
}& > summary {& > div  > i{font-size: 14px;
}}
& > article a { color: $COL22;
 }
 }
/* Frame:55 */
.MES55 {
padding: 0;

border-width: 4px;
border-style: solid;
border-color: $COL7;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES55 {
padding: 0;

border-width: 4px;
border-style: solid;
border-color: $COL7;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Content Wizard:56 */
.MES56 {
background-color: $COL12;
border-radius: 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 10px 10px 20px 10px;

@media #{$medium-up} {
padding: 15px 15px 30px 15px;

}
@media #{$large-up} {
padding: 20px 30px 40px 30px;

}
 }
.MES56 {
background-color: $COL12;
border-radius: 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 10px 10px 20px 10px;

@media #{$medium-up} {
padding: 15px 15px 30px 15px;

}
@media #{$large-up} {
padding: 20px 30px 40px 30px;

}
 }
/* Dark Black:57 */
.MES57 {
background-color: $COL4;
color: $COL24;
padding: 15px;

 }
.MES57 {
background-color: $COL4;
color: $COL24;
padding: 15px;

h1.MEC57, h2.MEC57, h3.MEC57, h4.MEC57, h5.MEC57, h6.MEC57 { color: $COL24;
 }
 }
a.MEC57 { color: $COL5;
&:hover { color: $COL4; }
 }
cite.MEC57{
color: $COL24;
}
/* Break out panel:32 */
.MES32 {
background-color: $COL4;
& > .underlay, & > .inner-overlay { opacity: 0.8 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://artsphere.me/img/7213/1117');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL14;
min-height:200px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 550px;};
padding: 15px;

@media #{$large-up} {
padding: 30px 50px;

}
 }
.MES32 {
background-color: $COL4;
& > .underlay, & > .inner-overlay { opacity: 0.8 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://artsphere.me/img/7213/1117');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL14;
min-height:200px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 550px;};
padding: 15px;

@media #{$large-up} {
padding: 30px 50px;

}
h1.MEC32, h2.MEC32, h3.MEC32, h4.MEC32, h5.MEC32, h6.MEC32 { color: $COL14;
 }
 }
cite.MEC32{
color: $COL14;
}
/* Dark:30 */
.MES30 {
background-color: $COL23;
color: $COL25;
 }
.MES30 {
background-color: $COL23;
color: $COL25;
h1.MEC30, h2.MEC30, h3.MEC30, h4.MEC30, h5.MEC30, h6.MEC30 { color: $COL25;
 }
 }
cite.MEC30{
color: $COL25;
}
/* Primary:2 */
.MES2 {
background-color: $COL4;
color: $COL14;
 }
/* Shade 2:15 */
.MES15 {
background-color: $COL9;
 }
.MES15 {
background-color: $COL9;
 }
/* Primary:3 */
.MES3 {
background-color: $COL4;
color: $COL14;
 }
.MES3 {
background-color: $COL4;
color: $COL14;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: $COL14;
 }
 }
cite.MEC3{
color: $COL14;
}
/* Secondary:4 */
.MES4 {
background-color: $COL5;
color: $COL21;
 }
/* Secondary:5 */
.MES5 {
background-color: $COL5;
color: $COL21;
 }
.MES5 {
background-color: $COL5;
color: $COL21;
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: $COL21;
 }
 }
cite.MEC5{
color: $COL21;
}
/* Alternate:6 */
.MES6 {
background-color: $COL6;
color: $COL27;
 }
/* Alternate:7 */
.MES7 {
background-color: $COL6;
color: $COL27;
 }
.MES7 {
background-color: $COL6;
color: $COL27;
h1.MEC7, h2.MEC7, h3.MEC7, h4.MEC7, h5.MEC7, h6.MEC7 { color: $COL27;
 }
 }
cite.MEC7{
color: $COL27;
}
/* Light:8 */
.MES8 {
background-color: $COL7;
color: $COL17;
 }
/* Light:9 */
.MES9 {
background-color: $COL7;
color: $COL17;
 }
.MES9 {
background-color: $COL7;
color: $COL17;
h1.MEC9, h2.MEC9, h3.MEC9, h4.MEC9, h5.MEC9, h6.MEC9 { color: $COL17;
 }
 }
cite.MEC9{
color: $COL17;
}
/* Shade 1:10 */
.MES10 {
background-color: $COL8;
color: $COL18;
 }
/* Shade 1:11 */
.MES11 {
background-color: $COL8;
color: $COL18;
 }
.MES11 {
background-color: $COL8;
color: $COL18;
h1.MEC11, h2.MEC11, h3.MEC11, h4.MEC11, h5.MEC11, h6.MEC11 { color: $COL18;
 }
 }
cite.MEC11{
color: $COL18;
}
/* top menu:12 */
nav.me-Menu.MES12 {
& .menu-item.MEC12, & .menu-item.MEC12 > div.MEC12{ & > a.MEC12{color: $COL4;
}
 &:hover > a.MEC12{color: $COL9;
}
 }
&.horizontal > .menu-item.MEC12 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC12 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC12 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC12 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 15px 10px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* grey dotted line:13 */
.MES13 {
padding: 30px 0;

& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: $COL9;}
& > hr {border-top-style: dashed;}
 }
/* Shade 2:14 */
.MES14 {
background-color: $COL9;
 }
/* Shade 2:16 */
.MES16 {
background-color: $COL9;
color: $COL19;
 }
/* Dark:29 */
.MES29 {
background-color: $COL23;
color: $COL24;
 }
/* Shade 2:17 */
.MES17 {
background-color: $COL9;
color: $COL19;
 }
.MES17 {
background-color: $COL9;
color: $COL19;
h1.MEC17, h2.MEC17, h3.MEC17, h4.MEC17, h5.MEC17, h6.MEC17 { color: $COL19;
 }
 }
cite.MEC17{
color: $COL19;
}
/* Subscribe button:18 */
.MES18 {
background-color: $COL11;
&:hover { background-color: $COL6;}
color: $COL5;
&:hover { color: $COL5;}
font-size: 16px;
@media #{$medium-up} {
font-size: 18px;
};
@media #{$large-up} {
font-size: 20px;
};
border-radius: 50px;
padding: 15px 40px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Hover:19 */
.MES19 {
background-color: $COL1;
&:hover, &.hover { background-color: $COL34;}
 }
.MES19 {
background-color: $COL1;
&:hover, &.hover { background-color: $COL34;}
 }
/* Page padding:20 */
.MES20 {
padding: 15px;

@media #{$large-up} {
padding: 20px 0 60px 0;

}
 }
.MES20 {
padding: 15px;

@media #{$large-up} {
padding: 20px 0 60px 0;

}
 }
a.MEC20 { color: $COL23;
&:hover { color: $COL9; }
 }
/* Form style:21 */
.me-block.me-Form.MES21,  body.MES21 {background-color:transparent;
 color: $COL4;
 
 input[type='submit']{border-style: solid;background-color: $COL22;
&:hover {background-color: $COL15}
border-color: $COL1;
border-width: 1px;
color: $COL5;
&:hover {color: $COL5}
padding: 15px;

@media #{$large-up} {
padding: 15px 40px;

}
border-radius: 50px;
}
@include placeholder($COL4);
input:not([type='submit']), textarea, select {border-style: solid;
border-width: 1px;
border-color: $COL9;
background-color: $COL1;
color: $COL4;
}
::-webkit-input-placeholder { color: $COL9;}
:-moz-placeholder {color: $COL9;} 
::-moz-placeholder {color: $COL9;}
:-ms-input-placeholder {color: $COL9;}

 .me-wrap-text a {color: $COL11;
} }
/* Filter Button:22 */
.MES22 {
background-color: $COL1;
&:hover { background-color: $COL1;}
color: $COL6;
&:hover { color: $COL6;}
font-size: 16px;
@media #{$medium-up} {
font-size: 14.4px;
};
@media #{$large-up} {
font-size: 16px;
};
padding: 5px 10px;

border-width: 1px;
border-style: solid;
border-color: $COL6;
 }
/* Responsive menu:23 */
nav.responsive-menu {
.menu-item.MEC23{background-color: $COL1;
}
& .menu-item.MEC23, & .menu-item.MEC23 > div.menu-item-wrap{ & > a.MEC23, & > i{color: $COL4;
font-size: 19.2px;
@media #{$medium-up} {
font-size: 16px;
}text-transform: lowercase;
text-align: center;
}
  }
& .menu-item.MEC23 { border:0;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 5px;}

& .sub-menu{ .sub-menu {}}

 }
/* Break out panel:24 */
.MES24 {
background-color: $COL4;
& > .underlay, & > .inner-overlay { opacity: 0.1 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://artsphere.me/img/68/27');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL14;
padding: 15px;

@media #{$large-up} {
padding: 30px 50px;

}
 }
.MES24 {
background-color: $COL4;
& > .underlay, & > .inner-overlay { opacity: 0.1 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://artsphere.me/img/68/27');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL14;
padding: 15px;

@media #{$large-up} {
padding: 30px 50px;

}
h1.MEC24, h2.MEC24, h3.MEC24, h4.MEC24, h5.MEC24, h6.MEC24 { color: $COL14;
 }
 }
cite.MEC24{
color: $COL14;
}
/* Shade 3:25 */
.MES25 {
background-color: $COL15;
color: $COL20;
 }
/* Shade 3:26 */
.MES26 {
background-color: $COL15;
color: $COL20;
 }
.MES26 {
background-color: $COL15;
color: $COL20;
h1.MEC26, h2.MEC26, h3.MEC26, h4.MEC26, h5.MEC26, h6.MEC26 { color: $COL20;
 }
 }
cite.MEC26{
color: $COL20;
}
/* Warning:27 */
.MES27 {
font-size: 14.4px;
@media #{$medium-up} {
font-size: 12.8px;
};
@media #{$large-up} {
font-size: 14.4px;
};
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 10px;

border-width: 2px;
border-style: solid;
border-color: $COL10;
border-top-style: dashed;
border-right-style: dashed;
border-bottom-style: dashed;
border-left-style: dashed;
 }
.MES27 {
font-size: 14.4px;
@media #{$medium-up} {
font-size: 12.8px;
};
@media #{$large-up} {
font-size: 14.4px;
};
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 10px;

border-width: 2px;
border-style: solid;
border-color: $COL10;
border-top-style: dashed;
border-right-style: dashed;
border-bottom-style: dashed;
border-left-style: dashed;
 }
cite.MEC27{
font-size: 14.4px;
@media #{$medium-up} {
font-size: 12.8px;
};
@media #{$large-up} {
font-size: 14.4px;
};
}
/* Green Subscribe button:28 */
.MES28 {
background-color: $COL22;
&:hover { background-color: $COL26;}
color: $COL5;
&:hover { color: $COL5;}
font-size: 19.2px;
@media #{$medium-up} {
font-size: 21.6px;
};
@media #{$large-up} {
font-size: 20px;
};
border-radius: 50px;
padding: 15px 40px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Small text:58 */
.MES58 {
font-size: 11.2px;
@media #{$large-up} {
font-size: 12.6px;
};
 }
.MES58 {
font-size: 11.2px;
@media #{$large-up} {
font-size: 12.6px;
};
 }
cite.MEC58{
font-size: 11.2px;
@media #{$large-up} {
font-size: 12.6px;
};
}
