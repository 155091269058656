$COL1: rgba(0,0,0,0); //
$COLFLAT1: #808080; //
$COL2: #1b1b1b; //
$COLFLAT2: #1b1b1b; //
$COL3: #999999; //
$COLFLAT3: #999999; //
$COL4: #1b1b1b; //
$COLFLAT4: #1b1b1b; //
$COL5: #ffffff; //
$COLFLAT5: #ffffff; //
$COL6: #333333; //
$COLFLAT6: #333333; //
$COL7: #f2f2f2; //
$COLFLAT7: #f2f2f2; //
$COL8: #e8e8e8; //
$COLFLAT8: #e8e8e8; //
$COL9: #888888; //
$COLFLAT9: #888888; //
$COL10: #c8c8c8; //
$COLFLAT10: #c8c8c8; //
$COL11: #ff015b; //black
$COLFLAT11: #ff015b; //black
$COL12: rgba(242,242,242,0.769); //Transparent
$COLFLAT12: #d8d8d8; //Transparent
$COL13: #1b1b1b; //
$COLFLAT13: #1b1b1b; //
$COL14: #ffffff; //
$COLFLAT14: #ffffff; //
$COL15: #ff015b; //
$COLFLAT15: #ff015b; //
$COL16: #1b1b1b; //
$COLFLAT16: #1b1b1b; //
$COL17: #1b1b1b; //
$COLFLAT17: #1b1b1b; //
$COL18: #1b1b1b; //
$COLFLAT18: #1b1b1b; //
$COL19: #ffffff; //
$COLFLAT19: #ffffff; //
$COL20: #ffffff; //
$COLFLAT20: #ffffff; //
$COL21: #1b1b1b; //
$COLFLAT21: #1b1b1b; //
$COL22: #43a047; //green
$COLFLAT22: #43a047; //green
$COL23: #43a047; //
$COLFLAT23: #43a047; //
$COL24: #ffffff; //
$COLFLAT24: #ffffff; //
$COL25: #ffffff; //
$COLFLAT25: #ffffff; //
$COL26: rgba(67,160,71,0.769); //green trans
$COLFLAT26: #519954; //green trans
$COL27: #ffffff; //
$COLFLAT27: #ffffff; //
$COL28: rgba(0,0,0,0.6); //black 60%
$COLFLAT28: #333333; //black 60%
$COL29: #3b7ea1; //blue
$COLFLAT29: #3b7ea1; //blue
$COL30: #e64d3e; //Red
$COLFLAT30: #e64d3e; //Red
$COL31: #e6a93e; //Red
$COLFLAT31: #e6a93e; //Red
$COL32: #f2f2f2; //
$COLFLAT32: #f2f2f2; //
$COL33: #f2f2f2; //
$COLFLAT33: #f2f2f2; //
$COL35: #e8e8e8; //
$COLFLAT35: #e8e8e8; //
$COL34: rgba(255,255,255,0.78); //77% Transparent white
$COLFLAT34: #e3e3e3; //77% Transparent white
$COL36: #1b1b1b; //
$COLFLAT36: #1b1b1b; //
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1248px,
  xxlarge: 1936px,
);
$global-width: 1200px;
